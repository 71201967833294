import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {

  static values = { contact: String }

  link(){
    this.request("POST", "/contacts/actions/links")
  }

  unlink(){
    this.request("DELETE", "/contacts/actions/links")
  }

  interest(){
    this.request("POST", "/contacts/actions/interests")
  }

  disinterest(){
    this.request("DELETE", "/contacts/actions/interests")
  }

  remove(){
    this.request("DELETE", "/contacts/actions/exclusions")
  }

  checkAll(){
    document.getElementById('checkBoxAll').click()
  }

  checkOne(){
    console.log('check_'+ this.contactValue)
    document.getElementById('check_'+ this.contactValue).click()
  }

  showAll(){
    console.log(this.checked)
  }

  request(method, url){
    let data = new FormData()    

    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    var self = this;

    fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      },      
      method: method,
      body: data
    })
    .then(function(response) {
      self.checkboxAllTarget.checked = false;
    })
    
  }

}
