import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "id", "content", "output", "row" ]
  static values = { url: String, endereco: String }

  validate(){
    var content = this.contentTarget.value;
    if (!content){
      this.contentTarget.className = '.border-red-700'
      event.stopImmediatePropagation()
    }
  }

  update(){
    var id = this.idTarget.value;
    var content = this.contentTarget.value;
    var output = this.outputTarget;
    var payload = {
      annotation_id: id,
      annotation: content
    }

    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.text())
      .then(function(html) {
        var split_content = html.split("#")
        if (payload.annotation_id) {
          var row = document.getElementById(id);
          row.cells[0].children[0].innerText = split_content[0];
          row.cells[1].children[0].innerText = split_content[1];
        } else {
          var newRow   = output.insertRow(1);
          var first_cell  = newRow.insertCell(0);
          var second_cell  = newRow.insertCell(1);
          var third_cell  = newRow.insertCell(2);
          var last_cell  = newRow.insertCell(3);
          first_cell.className = 'px-6 py-4 whitespace-nowrap text-center';
          second_cell.className = 'px-6 py-4 text-left';
          third_cell.className = 'px-6 py-4 whitespace-nowrap text-center';
          last_cell.className = 'px-6 py-4 whitespace-nowrap text-center';
          first_cell.innerHTML += split_content[0];
          second_cell.innerHTML += split_content[1];
          third_cell.innerHTML += split_content[2];
          last_cell.innerHTML += split_content[3];
        }
       })

    this.contentTarget.value = '';
    this.idTarget.value = '';
  }

  edit(event){
    event.preventDefault();
    var row = this.rowTarget;
    var content = row.cells[1].textContent
                              .replace(/[\n\r]+|[\s]{2,}/g, ' ')
                              .trim();
    var textarea = document.getElementById('contract_annotation') || document.getElementById('client_annotation')
    textarea.value = content;

    var hidden_id = document.getElementById('contract_id') || document.getElementById('client_id')
    hidden_id.value = row.id
  }

  delete() {
    var row = this.rowTarget;
    fetch(this.urlValue, {
      method: 'DELETE'
    })
      .then(function(response){
        if (response.ok) {
          row.parentNode.removeChild(row);
        }
      })

  }
}
