import { Controller } from "stimulus"

export default class extends Controller {

  markAsConnected(event) {
    event.preventDefault()

    let data = new FormData()

    let markedCheckboxes = document.querySelectorAll('input[type="checkbox"]:checked')

    //markedCheckboxes.forEach((checkbox) => data.append("ids[]", checkbox.value))

    console.log(markedCheckboxes)

    markedCheckboxes.forEach((checkbox) => console.log(checkbox.value))

    // fetch("/contacts/actions/links", {
    //   method: "POST",
    //   body: data
    // });
  }
}