import { Controller } from "stimulus"

export default class CustomTabs extends Controller {
  static targets = ['tab', 'panel']

  openModal(event) {
    let id = event.target.parentNode.id;
    if (!id) id = event.target.parentNode.parentNode.id;
    const modal = document.getElementById("edit-credit-"+id);
    const overlay = document.getElementById("overlay-"+id);
    modal.classList.remove('hidden');
    overlay.classList.remove('hidden');
  }

  closeModal(event) {
    const id = event.target.id;
    const modal = document.getElementById("edit-credit-"+id);
    const overlay = document.getElementById("overlay-"+id);
    modal.classList.add('hidden');
    overlay.classList.add('hidden');
    
  }

  switchSelectRecurrentCredit(event) {
    const value = event.target.value;
    const idSplitted = event.target.id.split("-");
    const id = idSplitted[1];
    const day_month = id ? document.getElementById("dayMonth-"+id) : document.getElementById("dayMonth");
    const day_week = id ? document.getElementById("dayWeek-"+id) :  document.getElementById("dayWeek");

    if (value == 'weekly') {
      day_month.style.display = "none";
      day_week.style.display = "block";
    } else {
      day_month.style.display = "block";
      day_week.style.display = "none";
    }
  }

  setDateInput(event) {
    const input = event.target;
    const value = input.value;

    const endDateEl = document.getElementById("end_date")
    const beginDateEl = document.getElementById("begin_date")

    const cleaned = value.replace(/\D/g, '');
    
    const masked = cleaned.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, function(match, day, month, year) {
        let result = '';
        if (day) result += day + (month ? '/' : '');
        if (month) result += month + (year ? '/' : '');
        if (year) result += year;
        return result;
    });

    input.value = masked;

    const maxLength = 10;
    if (value.length > maxLength) {
        input.value = input.value.slice(0, maxLength);
    }

    if (event.target.name == "end_date" && value.length >= maxLength) 
      this.validateDate(input.value, beginDateEl.value);
    else if (event.target.name == "begin_date" && value.length >= maxLength)
      this.validateDate(endDateEl.value, input.value);

    const beginDateErrorEl = document.getElementById("begin_date_error");
    const endDateErrorEl = document.getElementById("end_date_error");

    if (!beginDateErrorEl.innerText && !endDateErrorEl.innerText) {
      if (beginDateEl.value && endDateEl.value) {
        console.log("toggle");
        this.toggleSubmitButton(document.getElementById("recurrent-credit-submit-btn"), false);
      }
    }
  }

  validateDate(endDate, beginDate) {
    const beginDateErrorEl = document.getElementById("begin_date_error");
    const endDateErrorEl = document.getElementById("end_date_error");
    const today = new Date();
    
    const [day, month, year] = endDate.split("/").map(Number);
    const endDateConverted = new Date(year, month - 1, day);
    
    if (!this.isValidDate(beginDate)) {
      if (endDateConverted < today)
        beginDateErrorEl.innerText = "Data de início não pode ser menor que a data de hoje";
      else
        beginDateErrorEl.innerText = "";
    } else {
      const [beginDay, beginMonth, beginYear] = beginDate.split("/").map(Number);
      const beginDateConverted = new Date(beginYear, beginMonth - 1, beginDay);
      
      if (beginDateConverted > endDateConverted)
        endDateErrorEl.innerText = "Data de término não pode ser menor que a data de início";
      else
        endDateErrorEl.innerText = "";
    }
  }

  isValidDate(dateString) {
    if (!dateString) return false;
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getDate() === day &&
      date.getMonth() === month - 1 &&
      date.getFullYear() === year
    );
  }
  
  toggleSubmitButton(buttonEl, value = true) {
    buttonEl.disabled = value;
  }

  setTotalValue(e) {
    const credit_hours = document.getElementById("credit_hours")
    const hour_value = document.getElementById("credit_hour_value")
    const total_value = document.getElementById("credit_total_value")
    const submitBtn = document.getElementById("credit-submit-btn")

    if (!credit_hours.value || credit_hours.value < 0 || hour_value.value < 0 || !hour_value.value) 
      submitBtn.disabled = true
    else
      submitBtn.disabled = false


    if (e.target.dataset.int) {
      const val = e.target.value.replace(/[^0-9]/g, "");
      e.target.value = val;
    }

    const result = credit_hours.value * hour_value.value;
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    total_value.value = formatter.format(result);
  }

  connect() {
      window.addEventListener("hashchange", function() {
          window.scrollTo(0, 0);
      }, false);
      this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
      this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
      if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
      this.showTab()
  }

  change(event) {
      event.preventDefault()
      console.log("chane")

      // If target specifies an index, use that
      if (event.currentTarget.dataset.index) {
          this.index = event.currentTarget.dataset.index

          // If target specifies an id, use that
      } else if (event.currentTarget.dataset.id) {
          this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

          // Otherwise, use the index of the current target
      } else {
          this.index = this.tabTargets.indexOf(event.currentTarget)
      }

      window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  showTab() {
      this.tabTargets.forEach((tab, index) => {
          const panel = this.panelTargets[index]

          if (index === this.index) {
              panel.classList.remove('hidden')
              tab.classList.remove(...this.inactiveTabClasses)
              tab.classList.add(...this.activeTabClasses)

              // Update URL with the tab ID if it has one
              // This will be automatically selected on page load
              if (tab.id) {
                  location.hash = tab.id
              }
          } else {
              panel.classList.add('hidden')
              tab.classList.remove(...this.activeTabClasses)
              tab.classList.add(...this.inactiveTabClasses)
          }
      })
  }

  get index() {
      return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
      this.data.set('index', (value >= 0 ? value : 0))
      this.showTab()
  }

  get anchor() {
      return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }
}
