import { Application } from "@hotwired/stimulus"
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Clipboard from "stimulus-clipboard"
import { Alert, Modal, Dropdown, Popover } from "tailwindcss-stimulus-components"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import CustomTabs from "./tabs_component"
import CreditComponent from "./credit_component"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Configure Stimulus development experience
// application.debug = false
// window.Stimulus   = application

application.register('checkbox-select-all', CheckboxSelectAll)
application.register("clipboard", Clipboard)

application.register("dropdown", Dropdown)
application.register('alert', Alert)
application.register('popover', Popover)
application.register('modal', Modal)

application.register('tabs', CustomTabs)
application.register('mycredits', CreditComponent)
