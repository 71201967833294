import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sidebarContainer", "icon", "link", "mainContainer" ]

  toggle() {
    if (this.sidebarContainerTarget.dataset.expanded === "1") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    this.sidebarContainerTarget.classList.remove("sm:w-1/5")
    this.mainContainerTarget.classList.remove("sm:w-4/5")
    this.sidebarContainerTarget.classList.add("w-10")
    this.mainContainerTarget.classList.add("w-full")

    this.linkTargets.forEach(link => {
      link.classList.add("sr-only")
    })
    this.sidebarContainerTarget.dataset.expanded = "0"
  }

  expand() {
    this.sidebarContainerTarget.classList.remove("w-10")
    this.mainContainerTarget.classList.remove("w-full")
    this.sidebarContainerTarget.classList.add("sm:w-1/5")
    this.mainContainerTarget.classList.add("sm:w-4/5")
    this.linkTargets.forEach(link => {
      link.classList.remove("sr-only")
    })
    this.sidebarContainerTarget.dataset.expanded = "1"
  }
}
