import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "clearIcon", "searchInput" ]

  connect(){
    this.showCleanIcon();
  }

  showCleanIcon() {
    const value = this.searchInputTarget.value.trim();
    if (value) {
      this.clearIconTarget.classList.remove("hidden");
    }

  }

}
